<template>
  <o-sidebar
    :fullheight="true"
    :fullwidth="false"
    :overlay="true"
    :right="true"
    v-model:open="sidebarOpen"
    contentClass="sidebar"
  >
    <o-button
      style="
        width: 2rem;
        position: absolute;
        right: 1rem;
        top: 1rem;
        padding-right: 0.5rem;
        background-color: #1f2937;
      "
      icon-left="times"
      label="X"
      @click="sidebarOpen = false"
    />
    <svg
      width="8rem"
      viewBox="0 0 1250 625"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xml:space="preserve"
      xmlns:serif="http://www.serif.com/"
      style="
        fill-rule: evenodd;
        clip-rule: evenodd;
        stroke-linejoin: round;
        stroke-miterlimit: 2;
      "
    >
      <g id="header-logo-p1">
        <path
          d="M691.667,304.011l31.733,0l0,48.267l31.2,0c29.689,0 44.533,15.733 44.533,47.2l0,97.066l-31.733,0l0,-92.533c0,-8.711 -1.778,-14.844 -5.333,-18.4c-3.556,-3.555 -9.6,-5.333 -18.134,-5.333l-20.533,0l0,116.266l-31.733,0l0,-192.533Z"
          style="
            fill: #ffaf00;
            fill-rule: nonzero;
            stroke: #8a8484;
            stroke-width: 4.17px;
          "
        />
        <path
          d="M839.933,468.544c5.689,0 10.311,-1.066 13.867,-3.2c3.556,-2.133 5.333,-5.066 5.333,-8.8l-1.333,-5.066l-3.2,-5.6l-23.975,-33.884c-6.594,-9.241 -9.892,-18.305 -9.892,-27.191c0,-10.131 3.423,-18.084 10.267,-23.861c6.844,-5.776 16.222,-8.664 28.133,-8.664l23.734,0l6.933,28l-15.733,0c-11.556,0 -17.334,3.644 -17.334,10.933c0,1.422 0.356,3.156 1.067,5.2c0.711,2.045 1.511,3.689 2.4,4.933l25.333,36.8c6.045,8.356 9.067,16.445 9.067,24.267c0,10.667 -3.556,19.022 -10.667,25.067c-7.111,6.044 -16.977,9.066 -29.6,9.066l-29.333,0l-6.4,-28l21.333,0Z"
          style="
            fill: #ffaf00;
            fill-rule: nonzero;
            stroke: #8a8484;
            stroke-width: 4.17px;
          "
        />
        <path
          d="M909.8,406.144c0,-22.222 5.289,-37.155 15.867,-44.8c10.577,-7.644 25.2,-11.466 43.866,-11.466c18.134,0 31.023,2.578 38.667,7.733c7.644,5.156 13.111,11.822 16.4,20c3.289,8.178 4.933,17.422 4.933,27.733c0,11.556 -3.777,30.223 -11.333,56c-7.556,25.778 -23.778,38.667 -48.667,38.667c-16,0 -28,-6.133 -36,-18.4c-8,-12.267 -13.955,-24.311 -17.866,-36.133c-3.911,-11.822 -5.867,-23.156 -5.867,-34l0,-5.334Zm32.267,4.971c0,7.267 0.933,14.623 2.8,22.069c1.866,7.446 4.977,15.69 9.333,24.731c4.356,9.042 9.378,13.563 15.067,13.563c4.622,0 9.022,-3.147 13.2,-9.44c4.177,-6.293 7.289,-13.339 9.333,-21.139c2.044,-7.8 3.422,-14.227 4.133,-19.28c0.711,-5.052 1.067,-9.352 1.067,-12.9c0,-10.991 -2.267,-19.102 -6.8,-24.331c-4.533,-5.229 -11.422,-7.844 -20.667,-7.844c-18.311,0 -27.466,10.637 -27.466,31.909l0,2.662Z"
          style="
            fill: #ffaf00;
            fill-rule: nonzero;
            stroke: #8a8484;
            stroke-width: 4.17px;
          "
        />
        <path
          d="M1040.47,352.278l11.733,0l0,-11.529c0,-11.62 3.098,-20.648 9.294,-27.084c6.196,-6.436 14.959,-9.654 26.289,-9.654l19.917,0l7.433,26.667l-15.191,0c-5.789,0 -9.904,1.034 -12.346,3.104c-2.442,2.069 -3.663,5.715 -3.663,10.937l0,7.559l20.8,0l0,28l-20.8,0l0,116.266l-31.733,0l0,-116.266l-11.733,0l0,-28Z"
          style="
            fill: #ffaf00;
            fill-rule: nonzero;
            stroke: #8a8484;
            stroke-width: 4.17px;
          "
        />
        <path
          d="M1104.47,352.278l12.8,0l0,-27.517l31.733,-9.617l0,37.134l23.467,0l0,28l-23.467,0l0,70.4c0,4.8 0.178,7.866 0.533,9.2c0.356,1.333 1.823,3.111 4.4,5.333c2.578,2.222 6.711,3.333 12.4,3.333l11.734,0l-7.929,28l-17.713,0c-11.631,0 -19.825,-2.711 -24.583,-8.133c-4.759,-5.422 -7.711,-10.222 -8.857,-14.4c-1.145,-4.178 -1.718,-10 -1.718,-17.467l0,-76.266l-12.8,0l0,-28Z"
          style="
            fill: #ffaf00;
            fill-rule: nonzero;
            stroke: #8a8484;
            stroke-width: 4.17px;
          "
        />
        <rect
          x="1115"
          y="582.844"
          width="11.9"
          height="13.7"
          style="
            fill: #ffaf00;
            fill-rule: nonzero;
            stroke: #8a8484;
            stroke-width: 4.17px;
          "
        />
        <path
          d="M1157.9,585.538c1.4,0.536 4.3,1.004 8.7,1.406l6.7,0l-2.8,9.6l-4.9,0c-4.6,0 -9.333,-0.648 -14.2,-1.946c-4.867,-1.297 -9.333,-4.857 -13.4,-10.68c-4.067,-5.823 -6.1,-12.394 -6.1,-19.714c0,-3.926 0.633,-7.47 1.9,-10.631c1.267,-3.161 3.383,-5.906 6.35,-8.235c2.967,-2.329 8.183,-3.494 15.65,-3.494c13.867,0 20.8,5.023 20.8,15.068c0,3.08 -0.7,6.01 -2.1,8.789c-1.4,2.779 -3.217,5.139 -5.45,7.081c-2.233,1.942 -4.233,3.482 -6,4.62c-1.767,1.139 -4.85,2.98 -9.25,5.525c1.333,1.272 2.7,2.142 4.1,2.611Zm0.5,-17.194c4,-3.4 6,-6.7 6,-9.9c0,-2.066 -0.85,-3.7 -2.55,-4.9c-1.7,-1.2 -4.017,-1.8 -6.95,-1.8c-3.6,0 -6.383,1.084 -8.35,3.25c-1.967,2.167 -2.95,5.217 -2.95,9.15c0,1.534 0.167,3.367 0.5,5.5c0.333,1.2 0.617,2.134 0.85,2.8c0.233,0.667 0.65,1.6 1.25,2.8c3,-1.266 5.35,-2.383 7.05,-3.35c1.7,-0.966 3.417,-2.15 5.15,-3.55Z"
          style="
            fill: #ffaf00;
            fill-rule: nonzero;
            stroke: #8a8484;
            stroke-width: 4.17px;
          "
        />
        <path
          d="M1202.8,542.444l-2.175,6.7l-3.064,8.9c-2.174,6.6 -3.261,11.767 -3.261,15.5c0,4.2 0.922,7.167 2.765,8.9c1.843,1.734 3.368,2.767 4.574,3.1c1.206,0.334 3.15,0.5 5.831,0.5l5.73,0l0,-43.6l11.9,0l0,54.1l-19.1,0c-15.933,0 -23.9,-7.3 -23.9,-21.9c0,-2.866 0.283,-5.766 0.85,-8.7c0.567,-2.933 1.167,-5.366 1.8,-7.3c0.633,-1.933 2.15,-5.933 4.55,-12l1.6,-4.2l11.9,0Z"
          style="
            fill: #ffaf00;
            fill-rule: nonzero;
            stroke: #8a8484;
            stroke-width: 4.17px;
          "
        />
      </g>
      <g id="header-logo-p2">
        <path
          d="M183.97,499.342l0,-186.842l96.8,0l7.734,28.533l-70.934,0l0,53.334l66.934,0l-8.267,29.066l-58.667,0l0,68.488c0,2.841 0.089,5.107 0.267,6.796c0.178,1.689 0.622,4.043 1.333,7.062l-30.4,18.388c-2.133,-4.984 -3.466,-8.855 -4,-11.613c-0.533,-2.758 -0.8,-7.162 -0.8,-13.212Z"
          style="
            fill: #ffbf00;
            fill-rule: nonzero;
            stroke: #8a8484;
            stroke-width: 4.17px;
          "
        />
        <rect
          x="299.97"
          y="312.5"
          width="31.733"
          height="192.533"
          style="
            fill: #ffbf00;
            fill-rule: nonzero;
            stroke: #8a8484;
            stroke-width: 4.17px;
          "
        />
        <path
          d="M442.904,505.033c-14.934,0 -27.334,-1.777 -37.2,-5.333c-9.867,-3.556 -21.067,-11.644 -33.6,-24.267c-12.534,-12.622 -18.8,-32 -18.8,-58.133c0,-19.022 4.933,-33.2 14.8,-42.533c9.866,-9.334 25.022,-14 45.466,-14l53.334,0l0,144.266l-24,0Zm-7.734,-27.466l0,-88.8l-20.375,0c-19.838,0 -29.758,10.311 -29.758,30.933c0,14.222 3.53,25.822 10.59,34.8c7.059,8.978 12.957,14.711 17.693,17.2c4.737,2.489 12.02,4.444 21.85,5.867Z"
          style="
            fill: #ffbf00;
            fill-rule: nonzero;
            stroke: #8a8484;
            stroke-width: 4.17px;
          "
        />
      </g>
      <path
        id="path826"
        d="M518.311,552.193c-1.865,-3.018 20.443,-68.7 37.226,-109.604c2.666,-6.498 0.369,-6.932 -24.444,-4.615c-23.876,2.23 -27.753,1.602 -30.248,-4.9c-1.574,-4.102 -0.884,-12.698 1.533,-19.103c7.759,-20.554 111.165,-187.255 115.301,-185.877c13.382,4.461 12.519,15.897 -5.266,69.787c-9.907,30.016 -17.281,55.305 -16.388,56.198c0.893,0.893 14.3,-0.477 29.794,-3.044c24.498,-4.059 29.049,-3.788 34.92,2.084c5.283,5.283 5.796,8.824 2.359,16.28c-4.309,9.348 -96.636,146.452 -116.547,173.071c-9.744,13.026 -23.311,17.698 -28.24,9.723l0,0Z"
        style="
          fill: #ff6000;
          fill-rule: nonzero;
          stroke: #a35c5c;
          stroke-width: 4.17px;
        "
      />
      <g id="header-logo-p3">
        <path
          d="M135.387,54.249l50.7,0l23.1,127.5l20.1,-127.5l48.3,0l-34.2,216.3l-48.295,0l-26.756,-119.082l-19.186,119.082l-47.963,0l34.2,-216.3Z"
          style="
            fill: #ffc748;
            fill-rule: nonzero;
            stroke: #8a8484;
            stroke-width: 4.17px;
          "
        />
        <path
          d="M301.887,54.249l97.8,0l-6.6,40.2l-48,0l-8.1,50.7l42,0l-6.3,40.2l-41.7,0l-13.8,85.2l-49.5,0l34.2,-216.3Z"
          style="
            fill: #ffc748;
            fill-rule: nonzero;
            stroke: #8a8484;
            stroke-width: 4.17px;
          "
        />
        <path
          d="M397.807,54.249l122.48,0l-6.6,40.2l-36.3,0l-27.9,176.1l-49.5,0l27.9,-176.1l-36.445,-0.024l6.365,-40.176Z"
          style="
            fill: #ffc748;
            fill-rule: nonzero;
            stroke: #8a8484;
            stroke-width: 4.17px;
          "
        />
        <path
          d="M525.387,219.249c6.6,0 12.3,2.6 17.1,7.8c4.8,5.2 7.2,11.6 7.2,19.2c0,7.8 -2.9,14.5 -8.7,20.1c-5.8,5.6 -12.8,8.4 -21,8.4c-7,0 -12.9,-2.6 -17.7,-7.8c-4.8,-5.2 -7.2,-11.7 -7.2,-19.5c0,-7.8 2.95,-14.45 8.85,-19.95c5.9,-5.5 13.05,-8.25 21.45,-8.25Z"
          style="
            fill: #ffc748;
            fill-rule: nonzero;
            stroke: #8a8484;
            stroke-width: 4.17px;
          "
        />
      </g>
    </svg>

    <h3 class="menu-title">flashsoft.eu</h3>

    <ul>
      <li v-for="(menu, index) in flashsoftMenu.links" :key="index">
        <a class="menu-pill" :href="menu.href">
          {{ menu.text }}
        </a>
      </li>
    </ul>
  </o-sidebar>
  <div class="hero lg:pb-12">
    <div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
      <header class="flex justify-between items-center py-4 md:py-8">
        <!-- logo - start -->
        <a
          href="/"
          class="
            inline-flex
            items-center
            text-black-800 text-2xl
            md:text-3xl
            font-bold
            gap-2.5
          "
          aria-label="logo"
        >
          <svg
            width="8rem"
            viewBox="0 0 1250 625"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            xml:space="preserve"
            xmlns:serif="http://www.serif.com/"
            style="
              fill-rule: evenodd;
              clip-rule: evenodd;
              stroke-linejoin: round;
              stroke-miterlimit: 2;
            "
          >
            <use href="#header-logo-p1" />
            <use href="#header-logo-p2" />
            <use href="#path826" />
            <use href="#header-logo-p3" />
          </svg>
        </a>
        <!-- logo - end -->

        <!-- nav - start -->
        <nav class="hidden lg:flex gap-12">
          <a
            href="/"
            class="
              text-gray-800
              dark:text-gray-200
              hover:text-yellow-500
              active:text-yellow-700
              text-lg
              font-semibold
              transition
              duration-100
            "
            >Home</a
          >

          <o-dropdown v-model="flashsoftMenu" aria-role="list">
            <template #trigger>
              <o-button style="background-color: transparent">
                <span
                  class="
                    inline-flex
                    items-center
                    text-yellow-500 text-lg
                    font-semibold
                    gap-1
                  "
                  >{{ flashsoftMenu.text }}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-yellow-200"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    style="display: inline"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </o-button>
            </template>

            <a
              class="menu-link"
              v-for="(menu, index) in flashsoftMenu.links"
              :key="index"
              :href="menu.href"
            >
              <o-dropdown-item
                :value="menu"
                aria-role="listitem"
                class="
                  gap-1
                  p-1
                  hover:text-gray-700 hover:dark:text-gray-200
                  dark:text-gray-300
                "
              >
                <div class="media">
                  <div class="media-content">
                    {{ menu.text }}
                  </div>
                </div>
              </o-dropdown-item>
            </a>
          </o-dropdown>
        </nav>
        <!-- nav - end -->

        <!-- buttons - start -->
        <div
          class="
            flex flex-col
            sm:flex-row sm:justify-center
            lg:justify-start
            gap-2.5
            -ml-8
          "
        >
          <button
            id="theme-switch"
            style="background: #ffffffde"
            class="
              ml-5
              w-16
              h-6
              rounded-full
              bg-white
              flex
              items-center
              transition
              duration-300
              focus:outline-none
              shadow
            "
          >
            <div
              id="svg-theme-switch"
              @click="themeSwitch"
              :class="`
                border border-white
                w-9
                h-9
                relative
                rounded-full
                transition
                duration-500
                transform
                ${
                  themeDark
                    ? `bg-gray-700
                translate-x-full`
                    : `bg-yellow-500
                -translate-x-2`
                } 
                p-1
                text-white`"
              v-html="themeSwitchIcon"
            ></div>
          </button>
        </div>

        <button
          type="button"
          class="
            inline-flex
            items-center
            lg:hidden
            bg-gray-200
            hover:bg-gray-300
            focus-visible:ring
            ring-indigo-300
            text-gray-500
            active:text-gray-700
            text-sm
            md:text-base
            font-semibold
            rounded-lg
            gap-2
            px-2.5
            py-2
          "
          style="background: #ffffffde"
          @click="toggleSidebar()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            />
          </svg>

          Menu
        </button>
        <!-- buttons - end -->
      </header>
      <component
        :is="isHome ? props.homeHero : undefined"
        :data="props.homeHeroData"
      ></component>
      <!-- menu - end -->
    </div>
  </div>
</template>

<script>
import { defineComponent, inject, ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { getThemeMode } from "@/util/index.js";

export default defineComponent({
  name: "Header",
  components: {},
  props: {
    homeHero: {
      required: true,
    },
    homeHeroData: {
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const isHome = computed(() => route.path.match(/(\/$|\/page\/\d+)/g));

    const isDarkTheme = () => getThemeMode() === "dark";

    const themeDark = ref(isDarkTheme());
    const darkIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z" />
</svg>`;

    const lightIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
</svg>`;
    const themeSwitchIcon = ref(isDarkTheme() ? darkIcon : lightIcon);

    let routes = inject("routes");
    let sidebarOpen = ref(false);
    const isSwitchingTheme = ref(false);

    const linksFs = routes.filter((route) => route.type === "flashsoft");
    let flashsoftMenu = ref({
      links: linksFs,
      text: "FlashSoft",
    });

    const themeSwitch = async () => {
      if (!isSwitchingTheme.value) {
        const isDarkmode = document.documentElement.classList.contains("dark");
        isSwitchingTheme.value = true;
        if (isDarkmode) {
          themeDark.value = false;
          document.documentElement.classList.remove("dark");
          document.documentElement.classList.add("light");
          themeSwitchIcon.value = lightIcon;
          localStorage.setItem("theme", "light");
        } else {
          themeDark.value = true;
          document.documentElement.classList.remove("light");
          document.documentElement.classList.add("dark");
          themeSwitchIcon.value = darkIcon;
          localStorage.setItem("theme", "dark");
        }
        isSwitchingTheme.value = false;
      }
    };

    const toggleSidebar = () => {
      sidebarOpen.value = !sidebarOpen.value;
    };

    onMounted(() => {
      console.log("Header Loaded");
      console.log(props);
    });

    return {
      flashsoftMenu,
      sidebarOpen,
      toggleSidebar,
      props,
      themeDark,
      themeSwitchIcon,
      themeSwitch,
      isHome,
    };
  },
});
</script>

<style lang="scss">
.media {
  align-items: flex-start;
  display: flex;
  text-align: left;
}
.media-content {
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
  overflow-y: hidden;
  overflow-x: auto;
}
.media-left {
  margin-right: 1rem;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}
nav .o-drop__menu {
  background: var(--glass-menu-bg);
}

.o-drop__menu--active {
  box-shadow: 0.03rem 0.1rem 0.2rem;
  margin-top: 0.5rem;
  padding: 0.2rem 1.6rem 0.1rem 0.4rem;
  background: var(--glassBg);
  box-shadow: 0 8px 32px 0 var(--glassShadow);
  color: var(--glassText);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.hero {
  background: url(/img/res/header-waves.svg),
    linear-gradient(
      180deg,
      var(--headerColor1) 0%,
      var(--headerColor2) 1.98%,
      var(--headerColor3) 100%
    );
  background-repeat: no-repeat;
  background-size: cover, auto;
}

.sidebar {
  background: url(/img/res/header-waves.svg),
    linear-gradient(180deg, #212529 0%, #36354a 61.98%, #3b3c68 100%);
  background-position: 50% 200px, top;
  background-repeat: no-repeat;
  background-size: cover, auto;
  min-width: 18rem;
  padding-top: 2rem;
  color: white;
  padding-left: 1.5rem;
}

.menu-link {
  & > div > div {
    position: relative;
  }

  & > div > div::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: var(--headerColor1);
    transform-origin: bottom right;
    transform: scaleX(0);
    transition: transform 0.5s ease;
  }

  & > div > div:hover::before {
    transform-origin: bottom left;
    transform: scaleX(1);
  }
}

.menu-pill {
  background-color: rgba(221, 221, 221, 0.068);
  border: none;
  color: #cfaf1e;
  padding: 0.2em 5.2rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 0.3rem 0.2rem;
  cursor: pointer;
  border-radius: 10rem;
  &:hover {
    border: 1px solid rgb(207, 175, 30);
  }
}
.menu-title {
  text-align: center;
  margin-right: 4em;
  /* margin: 2rem; */
  margin-top: 2em;
  margin-bottom: -0.3em;
  text-transform: uppercase;
  color: #b9b9b9;
  border: 1;
  border-bottom: 1;
  transform: skewX(156deg);
}
</style>
