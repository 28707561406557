<template>
  <!-- menu - start -->
  <div
    class="
      w-full
      hidden
      lg:block
      bg-gray-50
      border
      rounded-lg
      shadow-sm
      overflow-hidden
      glass
    "
  >
    <div class="max-w-screen-lg flex items-center gap-8 p-8 mx-auto">
      <div class="w-2/3 grid grid-cols-2 gap-8">
        <!-- link - start -->
        <a href="#" class="group flex gap-4">
          <div
            class="
              home-hero-icon
              w-10
              md:w-12
              h-10
              md:h-12
              flex
              justify-center
              items-center
              flex-shrink-0
              group-hover:bg-indigo-600
              group-active:bg-indigo-700
              text-white
              rounded-lg
              shadow-lg
              transition
              duration-100
            "
          >
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="1231.000000pt"
              height="1280.000000pt"
              viewBox="0 0 1231.000000 1280.000000"
              preserveAspectRatio="xMidYMid meet"
              class="hero-svg"
              style="width: 1rem"
            >
              <g
                id="hero-svg"
                transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
              >
                <path
                  d="M3094 12651 c-165 -45 -268 -125 -332 -259 -35 -72 -37 -83 -36 -172
1 -111 27 -202 99 -337 85 -161 179 -291 778 -1079 929 -1222 1361 -2105 1447
-2960 18 -173 8 -494 -19 -649 -174 -987 -1007 -2118 -2606 -3540 -206 -183
-372 -328 -740 -645 -612 -527 -808 -720 -900 -885 -99 -179 -83 -368 42 -492
160 -159 430 -185 863 -82 69 16 134 32 145 34 11 3 7 -2 -10 -9 l-30 -14 30
-12 c52 -21 95 -23 168 -11 126 22 317 76 477 136 25 9 81 30 125 47 44 16 90
34 103 39 12 5 32 13 45 18 12 5 43 18 69 30 26 12 51 21 56 21 8 0 82 33 114
50 10 6 22 10 27 10 6 0 38 13 73 29 59 27 92 41 171 75 61 26 198 88 282 126
33 16 85 39 115 53 30 14 80 36 110 50 30 13 105 48 165 77 61 29 148 70 195
92 135 64 1007 499 1205 601 207 107 325 170 643 338 104 56 220 118 260 141
17 10 47 26 65 36 17 10 70 38 117 63 47 24 87 46 90 49 3 3 34 21 70 39 36
19 81 43 100 54 19 11 54 30 78 43 43 24 94 53 180 100 26 15 65 37 87 49 22
12 58 32 80 45 22 12 58 32 80 43 22 12 42 24 45 27 4 4 29 18 115 64 11 6 22
13 25 16 3 3 28 17 55 30 28 14 52 27 55 30 8 8 62 40 100 58 19 9 37 20 38
25 2 4 7 7 12 7 4 0 38 18 74 40 36 22 70 40 76 40 5 0 10 5 10 10 0 6 6 10
13 10 6 0 48 23 92 50 44 28 85 50 92 50 7 0 13 5 13 10 0 6 7 10 15 10 8 0
15 5 15 10 0 6 5 10 10 10 6 0 198 113 428 251 229 139 427 257 439 263 13 6
23 14 23 19 0 4 4 7 10 7 9 0 123 69 147 90 7 5 14 10 17 10 6 0 126 75 143
90 7 5 14 10 17 10 3 0 47 27 98 60 51 33 100 65 108 70 46 28 656 434 660
440 3 4 34 26 69 50 35 23 71 49 80 56 9 7 63 47 121 90 58 43 107 81 110 84
3 3 30 25 60 50 74 61 143 130 136 137 -3 3 -12 0 -21 -7 -8 -7 -15 -10 -15
-7 0 2 41 35 92 73 379 281 604 500 684 667 113 237 36 515 -241 862 -691 866
-2862 2449 -4996 3642 -1105 619 -1980 1004 -2584 1140 -156 35 -418 42 -521
14z m7556 -6405 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z"
                />
                <path
                  d="M10725 6295 c-16 -13 -26 -24 -22 -25 4 0 20 11 35 25 34 32 26 32
-13 0z"
                />
                <path
                  d="M1768 1553 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"
                />
              </g>
            </svg>
          </div>

          <div>
            <div class="font-semibold mb-1">100% GAN</div>
            <p class="text-sm text-gray-500">
              All images are generated 100% by Generative Adversarial Networks.
            </p>
          </div>
        </a>
        <!-- link - end -->

        <!-- link - start -->
        <a href="#" class="group flex gap-4">
          <div
            class="
              home-hero-icon
              w-10
              md:w-12
              h-10
              md:h-12
              flex
              justify-center
              items-center
              flex-shrink-0
              group-hover:bg-indigo-600
              group-active:bg-indigo-700
              text-white
              rounded-lg
              shadow-lg
              transition
              duration-100
            "
          >
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="1231.000000pt"
              height="1280.000000pt"
              viewBox="0 0 1231.000000 1280.000000"
              preserveAspectRatio="xMidYMid meet"
              class="hero-svg"
              style="width: 1rem"
            >
              <use href="#hero-svg" />
            </svg>
          </div>

          <div>
            <div class="font-semibold mb-1">Unique</div>
            <p class="text-sm text-gray-500">
              Every image is guaranteed to have a unique sequence of pixels.
            </p>
          </div>
        </a>
        <!-- link - end -->

        <!-- link - start -->
        <a href="#" class="group flex gap-4">
          <div
            class="
              home-hero-icon
              w-10
              md:w-12
              h-10
              md:h-12
              flex
              justify-center
              items-center
              flex-shrink-0
              group-hover:bg-indigo-600
              group-active:bg-indigo-700
              text-white
              rounded-lg
              shadow-lg
              transition
              duration-100
            "
          >
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="1231.000000pt"
              height="1280.000000pt"
              viewBox="0 0 1231.000000 1280.000000"
              preserveAspectRatio="xMidYMid meet"
              class="hero-svg"
              style="width: 1rem"
            >
              <use href="#hero-svg" />
            </svg>
          </div>

          <div>
            <div class="font-semibold mb-1">Small for IPFS</div>
            <p class="text-sm text-gray-500">
              Very small in size to be loaded fast from IPFS.
            </p>
          </div>
        </a>
        <!-- link - end -->

        <!-- link - start -->
        <a href="#" class="group flex gap-4">
          <div
            class="
              home-hero-icon
              w-10
              md:w-12
              h-10
              md:h-12
              flex
              justify-center
              items-center
              flex-shrink-0
              group-hover:bg-indigo-600
              group-active:bg-indigo-700
              text-white
              rounded-lg
              shadow-lg
              transition
              duration-100
            "
          >
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="1231.000000pt"
              height="1280.000000pt"
              viewBox="0 0 1231.000000 1280.000000"
              preserveAspectRatio="xMidYMid meet"
              class="hero-svg"
              style="width: 1rem"
            >
              <use href="#hero-svg" />
            </svg>
          </div>

          <div>
            <div class="font-semibold mb-1">Promote AI</div>
            <p class="text-sm text-gray-500">
              Help promote the next generation content generation tools and
              models.
            </p>
          </div>
        </a>
        <!-- link - end -->
      </div>

      <!-- promo - start -->
      <div class="border222 w-1/3 border rounded-lg overflow-hidden">
        <div class="h-48 bg-gray-100">
          <img
            :src="`/img/nft-abstract-ai/${img.id}.jpeg`"
            loading="lazy"
            :alt="`NFT ID ${img.id}`"
            class="w-full h-full object-cover object-center"
          />
        </div>

        <div
          class="flex justify-between items-center p-3"
          style="background-color: rgb(44, 50, 53)"
        >
          <p style="font-size: 0.7rem" class="text-gray-500 text-sm">
            {{ img.id }}
          </p>

          <a
            :href="`/asset/${img.id}`"
            class="
              details-link
              inline-block
              bg-transparent
              focus-visible:ring
              ring-yellow-300
              text-sm
              font-semibold
              border
              rounded-lg
              outline-none
              transition
              duration-100
              px-3
              py-1
            "
            >Details</a
          >
        </div>
        <div
          ref="pgbarwrap"
          class="progress-wrap progress"
          data-progress-percent="100"
        >
          <div ref="pgbar" class="progress-bar progress"></div>
        </div>
      </div>
      <!-- promo - end -->
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";

export default defineComponent({
  name: "HomeHero",
  components: {},
  props: {
    data: {
      required: true,
    },
  },
  setup(props) {
    console.log(props);
    const nftDB = ref(props.data.nftDB);
    const img = ref({ id: null });
    const progress = ref(0);
    const pgbarwrap = ref(null);
    const pgbar = ref(null);
    const slideInterval = ref(null);

    const loadRandom = (lastImg) => {
      const nftDbWithoutLast = nftDB.value.filter(
        (img) => img.id !== lastImg.id
      );
      img.value =
        nftDbWithoutLast[Math.floor(Math.random() * nftDbWithoutLast.length)];
    };
    loadRandom(img);

    const pgBarStep = () => {
      if (pgbar.value) {
        pgbar.value.style.left = `${progress.value}%`;
        progress.value += 3;
      } else {
        clearInterval(slideInterval.value);
      }
    };

    const setIntervalFn = () => {
      if (progress.value <= 100) {
        pgBarStep();
      } else {
        loadRandom(img);
        progress.value = 0;
      }
    };

    onMounted(() => {
      slideInterval.value = setInterval(setIntervalFn, 300);
    });

    return {
      pgbarwrap,
      pgbar,
      img,
    };
  },
});
</script>

<style lang="scss">
.hero-svg {
  width: 1rem;
  height: 1rem;

  & > g > path {
    fill: aliceblue;
    stroke: #cebb10;
    stroke-width: 4.17px;
  }
}
.home-hero-icon {
  background-color: var(--headerColor2);

  &:hover {
    background-color: var(--headerColor3);
  }
}

.progress {
  width: 19.2em;
  height: 2px;
}
.progress-wrap {
  background: #f80;
  margin: 10px 0;
  overflow: hidden;
  position: absolute;
  .progress-bar {
    background: #ddd;
    left: 0;
    position: absolute;
    top: 0;
    transition: left 0.5s ease;
  }
}
</style>
