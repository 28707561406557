<template>
  <div class="dark:bg-gray-800 dark:text-white bg-white py-6 sm:py-8 lg:py-12">
    <div class="max-w-screen-lg px-4 md:px-8 mx-auto">
      <div class="grid sm:grid-cols-2 gap-8">
        <!-- content - start -->
        <div
          class="
            flex flex-col
            justify-center
            items-center
            sm:items-start
            md:py-24
            lg:py-32
          "
        >
          <p
            class="
              text-yellow-500 text-sm
              md:text-base
              font-semibold
              uppercase
              mb-4
            "
          >
            Error 404
          </p>
          <h1
            class="
              text-gray-800 text-2xl
              md:text-3xl
              font-bold
              text-center
              sm:text-left
              mb-2
              dark:text-gray-100
            "
          >
            Page not found
          </h1>

          <p class="text-gray-500 md:text-lg text-center sm:text-left mb-8">
            The page you’re looking for doesn’t exist.
          </p>

          <a
            href="/"
            class="
              inline-block
              bg-gray-200
              hover:bg-gray-300
              focus-visible:ring
              ring-indigo-300
              text-gray-500
              active:text-gray-700
              text-sm
              md:text-base
              font-semibold
              text-center
              rounded-lg
              outline-none
              transition
              duration-100
              px-8
              py-3
            "
            >Go home</a
          >
        </div>
        <!-- content - end -->

        <!-- image - start -->
        <div
          class="
            h-80
            md:h-auto
            bg-gray-100
            overflow-hidden
            shadow-lg
            rounded-lg
            relative
          "
        >
          <img
            src="/img/res/error-1590642916589-592bca10dfbf.jpg"
            loading="lazy"
            alt="Error Page Not Found"
            class="w-full h-full object-cover object-center absolute inset-0"
          />
        </div>
        <!-- image - end -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { onMounted } from "vue";
import { useHead } from "@vueuse/head";

export default defineComponent({
  name: "PageNotFound",
  components: {},
  setup() {
    onMounted(() => {
      useHead({
        title: "Page not found | nft.flashsoft.eu",
        description: "The page you’re looking for doesn’t exist.",
        meta: [
          {
            name: "robots",
            content: "noindex, nofollow",
          },
        ],
      });
    });
  },
});
</script>
