import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import PageNotFound from "../views/PageNotFound.vue";

const routes = [
  {
    name: "NotFound",
    path: "/:pathMatch(.*)*",
    alias: "/404",
    component: PageNotFound,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/page/:pageNo",
    name: "HomePaginated",
    component: Home,
  },
  {
    path: "/asset/:id",
    name: "Asset",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "asset" */ "../views/Asset.vue"),
  },
  {
    path: "/dbError",
    name: "DBError",
    component: () =>
      import(/* webpackChunkName: "dberror" */ "../views/DBError.vue"),
  },
  {
    type: "flashsoft",
    text: "index",
    path: "/flashsoft",
    href: "https://flashsoft.eu",
    beforeEnter() {
      location.href = "https://flashsoft.eu";
    },
  },
  {
    type: "flashsoft",
    text: "blog",
    path: "/blog",
    href: "https://blog.flashsoft.eu",
    beforeEnter() {
      location.href = "https://blog.flashsoft.eu";
    },
  },
  {
    type: "flashsoft",
    text: "gitlab",
    path: "/gitlab",
    href: "https://gitlab.flashsoft.eu",
    beforeEnter() {
      location.href = "https://gitlab.flashsoft.eu";
    },
  },
  {
    type: "flashsoft",
    text: "certDB",
    path: "/certdb",
    href: "https://flashsoft.eu/show-cert",
    beforeEnter() {
      location.href = "https://flashsoft.eu/show-cert";
    },
  },
  {
    type: "flashsoft",
    text: "projects",
    path: "/projects",
    href: "https://flashsoft.eu/projects",
    beforeEnter() {
      location.href = "https://flashsoft.eu/projects";
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export { routes, router };
