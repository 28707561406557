<template>
  <div
    style="box-shadow: 1px -2px 0px #1f2937"
    class="dark:bg-gray-800 dark:text-white bg-white py-6 sm:py-8 lg:py-12"
  >
    <div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
      <!-- text - start -->
      <!--<div class="mb-10 md:mb-16">
        <h2
          class="
            text-gray-800 text-2xl
            lg:text-3xl
            font-bold
            text-center
            mb-4
            md:mb-6
          "
        >
          Gallery
        </h2>

        <p class="max-w-screen-md text-gray-500 md:text-lg text-center mx-auto">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra
          turpis nunc justo, amet, dolor ut fermentum. Ac augue bibendum orci
          pulvinar cras consequat nulla.
        </p>
      </div>
      -->
      <!-- text - end -->

      <div class="grid grid-cols-2 sm:grid-cols-3 gap-4 md:gap-6 xl:gap-8">
        <!-- image - start -->
        <atropos v-for="(img, index) in currentSlice" :key="index">
          <router-link
            :to="{
              path: `/asset/${img.id}`,
            }"
            class="
              group
              h-48
              md:h-96
              flex
              justify-end
              items-end
              bg-gray-100
              overflow-hidden
              rounded-t-lg
              shadow-lg
              relative
            "
          >
            <img
              :src="`/img/nft-abstract-ai/${img.id}.jpeg`"
              loading="lazy"
              :alt="`NFT ID ${img.id}`"
              class="
                w-full
                h-full
                object-cover object-center
                absolute
                inset-0
                transform
                group-hover:scale-110
                transition
                duration-200
              "
            />

            <div
              class="
                bg-gradient-to-t
                from-gray-800
                via-transparent
                to-transparent
                opacity-50
                absolute
                inset-0
                pointer-events-none
              "
            ></div>

            <span
              class="
                inline-block
                text-gray-200 text-xs
                md:text-sm
                border border-gray-500
                rounded-lg
                backdrop-blur
                relative
                px-2
                md:px-3
                py-1
                mr-3
                mb-3
              "
              >NFT</span
            >
          </router-link>
          <div
            class="flex justify-between items-center bg-white p-3 rounded-b-lg"
            style="background-color: #2c3235"
          >
            <p class="hidden lg:block text-gray-500 text-sm">{{ img.id }}</p>
            <a
              :href="`/asset/${img.id}`"
              class="
                details-link
                inline-block
                bg-transparent
                focus-visible:ring
                ring-yellow-300
                text-sm
                font-semibold
                border
                rounded-lg
                outline-none
                transition
                duration-100
                px-3
                py-1
              "
              >Details</a
            >
          </div>
        </atropos>
        <!-- image - end -->
      </div>
    </div>

    <div class="flex justify-center pt-8">
      <router-link :to="`/page/${currentSlicePage + 1}`">
        <button
          @click="setCurentPage(currentSlicePage)"
          v-if="currentSlicePage >= minPage"
          class="
            border border-yellow-500
            text-yellow-500
            block
            rounded-sm
            font-bold
            py-4
            px-6
            mr-2
            flex
            items-center
            hover:bg-yellow-500 hover:text-white
          "
        >
          <svg
            class="h-5 w-5 mr-2 fill-current"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="-49 141 512 512"
            style="enable-background: new -49 141 512 512"
            xml:space="preserve"
          >
            <path
              id="XMLID_10_"
              d="M438,372H36.355l72.822-72.822c9.763-9.763,9.763-25.592,0-35.355c-9.763-9.764-25.593-9.762-35.355,0 l-115.5,115.5C-46.366,384.01-49,390.369-49,397s2.634,12.989,7.322,17.678l115.5,115.5c9.763,9.762,25.593,9.763,35.355,0 c9.763-9.763,9.763-25.592,0-35.355L36.355,422H438c13.808,0,25-11.193,25-25S451.808,372,438,372z"
            ></path>
          </svg>
          Previous page
        </button>
      </router-link>
      <router-link :to="`/page/${currentSlicePage + 1}`">
        <button
          @click="setCurentPage(currentSlicePage + 2)"
          v-if="maxPage > currentSlicePage + 2"
          class="
            border border-yellow-500
            text-yellow-500
            block
            rounded-sm
            font-bold
            py-4
            px-6
            mr-2
            flex
            items-center
            hover:bg-yellow-500 hover:text-white
          "
        >
          Next page
          <svg
            class="h-5 w-5 ml-2 fill-current"
            clasversion="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="-49 141 512 512"
            style="enable-background: new -49 141 512 512"
            xml:space="preserve"
          >
            <path
              id="XMLID_11_"
              d="M-24,422h401.645l-72.822,72.822c-9.763,9.763-9.763,25.592,0,35.355c9.763,9.764,25.593,9.762,35.355,0
            l115.5-115.5C460.366,409.989,463,403.63,463,397s-2.634-12.989-7.322-17.678l-115.5-115.5c-9.763-9.762-25.593-9.763-35.355,0
            c-9.763,9.763-9.763,25.592,0,35.355l72.822,72.822H-24c-13.808,0-25,11.193-25,25S-37.808,422-24,422z"
            />
          </svg>
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
/*
useHead({
  title,
  meta: [
    { name: "description", content: description },
    { name: "description", content: description },
    { property: "og:title", content: title },
    {
      property: "og:image",
      content: "https://repository-images.githubusercontent.com/343114407/620b7080-7b02-11eb-96bc-e2f02ef328a7",
    },
  ],
});
*/

// @ is an alias to /src
import Atropos from "atropos/vue/atropos-vue.esm";
import HomeHero from "@/components/HomeHero";
import { useRoute, useRouter } from "vue-router";
import { onMounted, markRaw, inject, ref, reactive, computed } from "vue";
import { useHead } from "@vueuse/head";

export default {
  name: "Home",
  emits: ["sendComponent"],
  components: { Atropos },

  setup(props, { emit }) {
    const perPage = 9;
    const CFWorkerBase = inject("CFWorkerBasePath");
    const nftDB = ref(null);

    const route = useRoute();
    const router = useRouter();
    const currentSlicePage = ref(0);
    const currentSlice = ref([]);

    const siteData = reactive({
      title: ``,
      description: `NFT Gallery, stats and and openSea links nft.flashsoft.eu`,
      ogImage: ``,
    });

    const maxPage = ref(0);
    const minPage = 1;

    const setPageData = () => {
      siteData.ogImage = `/img/nft-abstract-ai/${nftDB.value[40].id}.jpeg`;
      maxPage.value = ~~(nftDB.value.length / perPage) + 1;
    };

    const getAssetById = (id) => {
      return nftDB.value.filter((n) => n.id === id)[0];
    };

    router.beforeEach((to) => {
      if (to.path.startsWith("/asset/")) {
        currentSlicePage.value = 1;
        currentSlice.value = nftDB.value.slice(0, perPage);
        to.meta.asset = getAssetById(to.path.split("/")[2]);
      }
    });

    const setCurentPage = (pageNo) => {
      if (pageNo) {
        if (pageNo > maxPage.value || pageNo < minPage) {
          router.push({ path: "/404" });
        }
        currentSlicePage.value = pageNo - 1;
      } else {
        currentSlicePage.value = 0;
      }
      currentSlice.value = nftDB.value.slice(
        currentSlicePage.value * perPage,
        (currentSlicePage.value + 1) * perPage
      );

      siteData.title = `NFT Gallery - nft.flashsoft.eu - page ${
        currentSlicePage.value + 1
      }`;
    };

    onMounted(async () => {
      nftDB.value = await fetch(`${CFWorkerBase}/get-db`);

      if (nftDB.value.ok) {
        nftDB.value = JSON.parse(await nftDB.value.json());
      } else {
        router.push({ path: "/dberror", replace: true });
        return;
      }

      setPageData();

      console.log(nftDB.value);
      emit("sendComponent", {
        from: "homeComponent",
        component: markRaw(HomeHero),
        data: {
          nftDB: nftDB.value,
        },
      });
      setCurentPage(route.params.pageNo);
    });

    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      description: computed(() => siteData.description),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        {
          property: "og:title",
          content: computed(() => siteData.title),
        },
        {
          property: "og:image",
          content: computed(() => siteData.ogImage),
        },
      ],
    });

    return {
      currentSlice,
      currentSlicePage,
      maxPage,
      minPage,
      setCurentPage,
      Atropos,
      getAssetById,
      router,
    };
  },
};
</script>
