import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import { routes, router } from "./router";
//import nftDb from "./db/nft";
import { createHead } from "@vueuse/head";
import { Dropdown, Button, Sidebar } from "@oruga-ui/oruga-next";
import "@oruga-ui/oruga-next/dist/oruga.css";
import "atropos/atropos.min.css";
const CFWorkerBasePath =
  "https://worker-dev-nft-flashsoft-eu.t-worker.workers.dev";

//.provide("nftDb", nftDb)

createApp(App)
  .use(router)
  .use(Dropdown)
  .use(Button)
  .use(Sidebar)
  .use(createHead())
  .provide("CFWorkerBasePath", CFWorkerBasePath)
  .provide("routes", routes)
  .mount("#app");
